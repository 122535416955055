import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Header, Container, Grid, Segment } from "semantic-ui-react"
import Layout from "@src/components/layout"
import CtaLarge from "@src/components/cta-large"
import ContactForm from "@src/components/contact-form"
import { GatsbyImage } from "gatsby-plugin-image"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactPageQuery {
      image: file(relativePath: { eq: "1702314510-unipro-office-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  return (
    <Layout>
      <section className="ui vertical segment banner">
        <div className="ui container ui stackable vertically padded grid">
          <div className="row">
            <div className="computer only one wide computer column" />
            <div className="twelve wide computer sixteen wide mobile sixteen wide tablet column">
              <div className="content" style={{ margin: 0 }}>
                <h1 className="ui header h1">Drop us a note</h1>
                <h3 className="ui header h3">
                  <span>Engage with our team</span>
                </h3>
              </div>
            </div>
            <div className="computer only three wide computer column" />
          </div>
        </div>
      </section>
      <Segment as="section" vertical basic className="page-contact">
        <Grid as={Container} stackable>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <ContactForm />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <GatsbyImage
                image={data.image.childImageSharp.gatsbyImageData}
                alt=""
              />
              <Segment.Group as="div">
                <Segment as="div" className="address">
                  <address>
                    <Header as="h2" style={{ fontSize: "2.250em" }}>
                      Chichester
                    </Header>
                    Ilex Place,
                    <br />
                    Friary Lane,
                    <br />
                    PO19 1UF
                  </address>
                  <a href="tel:+441243539412">+44 (0)1243 539412</a>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <CtaLarge
        title="Get in touch to get started"
        subTitle="Let’s work together!"
        actionText="OK. LET’S GO!"
        url="mailto:info@unipro.io"
        backgroundColour="rgb(0, 255, 191)"
      />
    </Layout>
  )
}

ContactPage.defaultProps = {
  data: null,
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
  }),
}

export default ContactPage
